<template>
  <section>
    <div ref="pickup-schedule">
      <v-expansion-panels>
        <v-expansion-panel v-for="working in daysSchedule" :key="working.day">
          <v-expansion-panel-header>
            <div><span class="font-weight-medium">{{ $t(working.title) }}</span> <span v-if="working.hours.length">&emsp;({{ working.hours.length }} {{ $t('time ranges') }})</span></div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <p v-if="!working.hours.length" class="info-message">{{ $t('No working time ranges for this day added. You can add new using form below.') }}</p>
              <window-form
                v-else
                v-for="(hour, index) in working.hours"
                type="pickup"
                mode="edit"
                :edit-time-from="hour.from"
                :edit-time-to="hour.to"
                :day="working.day"
                :key="hour.from"
                :index="index"
              />
              <window-form
                v-if="formActive === working.day || !working.hours.length"
                :day="working.day"
                :key="working.day"
                type="pickup"
                mode="add"
              />
              <v-btn v-else dark depressed class="primary mt-8" @click="formActive = working.day">
                {{ $t('Add new time range for') }} {{ $t(working.title) }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row class="pt-2">
      <v-col cols="12">
        <v-btn dark depressed class="primary mr-2 my-1" @click="fillWithDefaults">
          {{ $t('Set defaults') }}
        </v-btn>
        <v-btn color="red" class="mr-2 my-1 white--text" @click="clean">
          {{ $t('Clean up') }}
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import WindowForm from '@/components/GeneralInformation/ScheduleInformation/WindowForm'
import { mapGetters } from 'vuex'

export default {
  components: {
    WindowForm
  },
  props: {
    partnerType: {
      type: String,
      required: false,
      default: () => 'food'
    }
  },
  computed: {
    ...mapGetters({
      timeRangesDefault: 'user/schedule/getDefaultPickupTimeRanges',
      getUserScheduleInformation: 'user/getUserScheduleInformation'
    })
  },
  data () {
    return {
      daysSchedule: [
        { title: 'Sunday', day: 'sunday', hours: [] },
        { title: 'Monday', day: 'monday', hours: [] },
        { title: 'Tuesday', day: 'tuesday', hours: [] },
        { title: 'Wednesday', day: 'wednesday', hours: [] },
        { title: 'Thursday', day: 'thursday', hours: [] },
        { title: 'Friday', day: 'friday', hours: [] },
        { title: 'Saturday', day: 'saturday', hours: [] },
      ],
      formActive: false
    }
  },
  mounted () {
    this.$bus.$on('schedule-pickup-window-add', this.addWorkingHour)
    this.$bus.$on('schedule-pickup-window-edit', this.editWorkingHour)
    this.$bus.$on('schedule-pickup-window-remove', this.deleteWorkingHour)

    let day = null
    this.daysSchedule.forEach(d => {
      day = this.getUserScheduleInformation.pickup.find(item => d.day === item.day)
      if (day) {
        d.hours = day.hours
      }
    })
  },
  beforeDestroy () {
    this.$bus.$off('schedule-pickup-window-add', this.addWorkingHour)
    this.$bus.$off('schedule-pickup-window-edit', this.editWorkingHour)
    this.$bus.$off('schedule-pickup-window-remove', this.deleteWorkingHour)
  },
  methods: {
    addWorkingHour (hour) {
      let day = this.daysSchedule.find(d => d.day === hour.day)
      day.hours.push(hour)
      this.$bus.$emit('schedule-pickup-changed', this.daysSchedule)
    },

    editWorkingHour (hour, index) {
      this.daysSchedule.find(d => (d.day === hour.day)).hours[index] = hour
      this.$bus.$emit('schedule-pickup-changed', this.daysSchedule)
    },

    deleteWorkingHour (hour) {
      let day = this.daysSchedule.find(d => (d.day === hour.day))
      let selectedDay = day.hours.find(d => (d.day === hour.day && d.from === hour.from && d.to === hour.to))
      let idx = day.hours.indexOf(selectedDay)
      day.hours.splice(idx, 1)
      this.$bus.$emit('schedule-pickup-changed', this.daysSchedule)
    },
    fillWithDefaults () {
      this.daysSchedule.forEach(day => {
        let hours = this.timeRangesDefault.map(h => {
          return {
            day: day.day,
            from: h.from,
            to: h.to
          }
        })
        day.hours = hours
      })
      this.$bus.$emit('schedule-pickup-changed', this.daysSchedule)
    },
    clean () {
      this.daysSchedule.forEach(day => {
        day.hours = []
      })
      this.$bus.$emit('schedule-pickup-changed', this.daysSchedule)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../../css/variables/colors';

  .info-message {
    margin: 0;
    color: map-get($colors, suva-gray);
  }
</style>
