export default {
  data: () => ({
    regex: {
      fullAddressStr: /\d.*\s|\s.*\d/
    }
  }),
  computed: {
    radUrl () {
      return process.env.VUE_APP_RAD_URL;
    }
  },
  methods: {
    getImageUrl (rawUrl) {
      return rawUrl ? process.env.VUE_APP_BASE_IMAGE_URL + rawUrl : 'https://www.korzyna.com/assets/placeholder.svg'
    }
  }
};
