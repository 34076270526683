<template>
  <section>
    <v-row class="m-0">
      <v-col cols="12" class="px-0_5">
        <v-card>
          <v-card-title>
            {{ $t('Address information') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :loading="tableOptions.loading"
                  :headers="tableOptions.headers"
                  :items="tableOptions.body"
                  item-key="id"
                  class="elevation-1 address-information__table"
                  sort-by="city"
                  sort-desc
                  :footer-props="{'items-per-page-text':$t('Rows per page:')}"
                  no-data-text=""
                >
                  <template v-if="!tableOptions.body.length" v-slot:body="">
                    <tbody>
                      <tr class="v-data-table__empty-wrapper">
                        <td colspan="7" class="py-8">
                          <data-not-found :error="tableOptions.error" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-else v-slot:body="{ items }">
                    <address-information-table-line
                      v-for="item in items" :key="item.id"
                      :item="item"
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-btn dark depressed class="primary mr-4" @click="showEditAddressDialog()">{{ $t('Add new address') }}</v-btn>
                <address-edit-modal :isShow="viewAddressDialog" :existing-address="editableAddress" @hideDialog="closeEditAddressDialog" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import i18n from "@/plugins/i18n";

  import AddressInformationTableLine from "@/components/GeneralInformation/AddressInformation/AddressInformationTableLine.vue";
  import AddressEditModal from "@/components/GeneralInformation/AddressInformation/AddressEditModal.vue";
  import DataNotFound from "@/components/DataNotFound.vue";

  export default {
    name: 'AddressInformation',
    components: {DataNotFound, AddressEditModal, AddressInformationTableLine},
    data: (vm) => ({
      tableOptions: {
        loading: true,
        options: {
          page: 1,
          totalPages: 0,
          itemsPerPage: 5,
        },
        headers: [
          { text: vm.$t('Street'), value: 'street' },
          { text: vm.$t('House number'), value: 'house_number' },
          { text: vm.$t('City'), value: 'city' },
          { text: vm.$t('State'), value: 'state' },
          { text: vm.$t('Post-code'), value: 'zip' },
          { text: vm.$t('Country'), value: 'country' },
          { text: vm.$t('Enabled'), value: 'enabled' },
        ],
        body: [],
        error: {
          icon: '',
          label: '',
          description: '',
          data: null
        }
      },
      editableAddress: null,
      viewAddressDialog: false,
    }),
    computed: {
      ...mapGetters({
        token: 'user/getAccessToken',
        currentUser: 'user/getUser',
        partnerAddressesList: 'partner/address/getPartnerAddressesList',
      })
    },
    methods: {
      ...mapActions({
        loadPartnerAddressesList: 'partner/address/loadPartnerAddressesList',
        addPartnerAddress: 'partner/address/addPartnerAddress',
        updatePartnerAddress: 'partner/address/updatePartnerAddress'
      }),
      updatePartnerAddressData () {
        this.tableOptions.loading = true;
        this.tableOptions.error.icon = '';
        this.tableOptions.error.label = '';
        this.tableOptions.error.description = '';
        if (!this.partnerAddressesList.length) {
          this.tableOptions.body = [];
          this.tableOptions.error.icon = 'mdi-database-sync';
          this.tableOptions.error.label = this.$t('No results found.');
          this.tableOptions.error.description = this.$t('Try again later or choose another selection.');
          this.tableOptions.loading = false;
          return;
        }
        this.tableOptions.body = this.partnerAddressesList.map(address => ({
          id: address.id,
          street: address.street || '-',
          house_number: JSON.parse(address.api_details).address.house_number || '-',
          city: address.city || '-',
          state: address.state || '-',
          zip: address.zip || '-',
          country: JSON.parse(address.api_details).address.country || '-',
          enabled: address.enabled || 0,
        }));
        this.tableOptions.loading = false;
      },
      async addAddress (address) {
        try {
          await this.addPartnerAddress({token: this.token, address})
          this.updatePartnerAddressData()
        } catch (err) {
          await this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: err.message || err,
            action1: { label: i18n.t('OK') }
          })
        }
      },
      async updateAddress (address) {
        try {
          await this.updatePartnerAddress({token: this.token, address})
        } catch (err) {
          await this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: err.message || err,
            action1: { label: i18n.t('OK') }
          })
        }
      },
      showEditAddressDialog (addressId) {
        if (addressId) {
          this.editableAddress = this.partnerAddressesList.find(address => address.id === addressId)
        }
        this.viewAddressDialog = true
      },
      closeEditAddressDialog (res, address) {
        if (!res.status) {
          this.viewAddressDialog = false
          this.editableAddress = null
          return
        }
        if (res.mode === 'update') {
          return this.updateAddress(address)
        }
        const sentData = {
          ...address,
          partner_id: this.currentUser.partner_id
        }
        return this.addAddress(sentData)
      },
    },
    beforeMount () {
      this.tableOptions.loading = true;
      this.updatePartnerAddressData();
    },
    mounted () {
      this.$bus.$on('update-address-data', this.updateAddress)
      this.$bus.$on('open-edit-address-modal', this.showEditAddressDialog)
    },
    beforeDestroy () {
      this.$bus.$off('update-address-data', this.updateAddress)
      this.$bus.$off('open-edit-address-modal', this.showEditAddressDialog)
    },
    watch: {
      partnerAddressesList () {
        this.updatePartnerAddressData();
      }
    }
  }
</script>

<style scoped lang="scss">
  .address-information__table {
    min-height: 332px;
    display: flex;
    flex-direction: column;
  }
</style>

<style lang="scss">
  .address-information__table > .v-data-table__wrapper {
    flex: 1 1 auto;
  }
  .address-information__table > .v-data-table__wrapper .v-data-table-header {
    & > tr > th {
      white-space: nowrap;
    }
  }
</style>
