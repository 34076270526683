<template>
  <div ref="special-schedule">
    <p v-if="!specialSchedule.length">{{ $t('No specific dates have been added. You can add a new one using the form below.') }}</p>

    <v-expansion-panels v-else>
      <v-expansion-panel v-for="special in specialSchedule" :key="special.day">
        <v-expansion-panel-header>
          <div>{{ $t('From', {symbol: ''}) }} {{ special.day }} {{ $t('to', {symbol: ''}) }} {{ special.day_to }} <span v-if="special.hours.length">&emsp;({{ special.hours.length }} {{ $t('windows') }})</span>
            <v-btn color="red" class="ml-5 white--text" @click="removeDaysRange(special)">
              {{ $t('Remove') }}
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <p v-if="!special.hours.length">{{ $t('No specific time windows for this date added. You can add new using form below.') }}</p>

            <window-form v-else
                             v-for="hour in special.hours"
                             mode="edit"
                             type="special"
                             :edit-time-from="hour.from"
                             :edit-time-to="hour.to"
                             :day="special.day"
                             :key="hour.from"
            />
            <v-spacer />
            <window-form v-if="formActive === special.day || !special.hours.length"
                             :day="special.day"
                             :key="special.day"
                             type="special"
                             mode="add"
            />
            <v-btn v-else dark depressed class="primary mr-2 my-1"
                   @click="formActive = special.day"
            >
              {{ $t('Add new time window') }}
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-spacer />

    <template>
      <date-form v-if="dateFormActive || !specialSchedule.length" />

      <v-btn v-else dark depressed class="primary mt-4"
             @click="dateFormActive = true"
      >
        {{ $t('Add new Special Date Range') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
import WindowForm from './WindowForm'
import DateForm from './DateForm'
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: 'SpecialSchedule',
  components: {
    WindowForm,
    DateForm
  },
  props: {
    partnerType: {
      type: String,
      required: false,
      default: () => 'food'
    }
  },
  data () {
    return {
      specialSchedule: [],
      formActive: false,
      dateFormActive: false
    }
  },
  mounted () {
    this.$bus.$on('schedule-special-date-add', this.addSpecialDate)
    this.$bus.$on('schedule-special-window-add', this.addSpecialHour)
    this.$bus.$on('schedule-special-window-remove', this.deleteSpecialHour)

    this.specialSchedule = this.getUserScheduleInformation.special;
  },
  beforeDestroy () {
    this.$bus.$off('schedule-special-date-add', this.addSpecialDate)
    this.$bus.$off('schedule-special-window-add', this.addSpecialHour)
    this.$bus.$off('schedule-special-window-remove', this.deleteSpecialHour)
  },
  computed: {
    ...mapGetters({
      getUserScheduleInformation: 'user/getUserScheduleInformation'
    })
  },
  methods: {
    addSpecialDate (date) {
      this.specialSchedule.push(date)
    },
    addSpecialHour (hour) {
      let date = this.specialSchedule.find(d => d.day === hour.day)
      if (!date) {
        return
      }
      date.hours.push(hour)
      this.$bus.$emit('schedule-special-changed', this.specialSchedule)
    },
    deleteSpecialHour (hour) {
      let day = this.specialSchedule.find(d => d.day === hour.day)
      let selectedDay = day.hours.find(d => (d.day === hour.day && d.from === hour.from && d.to === hour.to))
      let idx = day.hours.indexOf(selectedDay)
      day.hours.splice(idx, 1)
      this.$bus.$emit('schedule-special-changed', this.specialSchedule)
    },
    removeDaysRange (range) {
      let idx = this.specialSchedule.findIndex(d => d.day === range.day && d.day_to === range.day_to)
      if (idx >= 0) {
        this.specialSchedule.splice(idx, 1)
        this.$bus.$emit('schedule-special-changed', this.specialSchedule)
      }
    }
  }
}
</script>
