<template>
  <div class="text-xs-center">
    <v-dialog v-model="isShow" width="700" content-class="addresses-popup" @keydown.esc="closeAddressDialog" @keydown.enter="addAddress" persistent>
      <v-card>
        <v-card-title class="primary addresses-popup__title">{{existingAddress ? $t('Edit address') : $t('Add address')}}</v-card-title>
        <v-card-text class="addresses-popup__content">
          <v-row>
            <v-col cols="12">
              <l-map :zoom="24" :center="mapCenter" style="width: 100%; height: 350px;" :options="{ detectRetina: true, crossOrigin: true }" ref="partnerAddressMap" @click="toggleMarker">
                <l-tile-layer :url="tileUrl" />
                <l-marker :lat-lng="markerPosition" />
              </l-map>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="addresses-popup__content_search-field"
                v-model="searchField.value"
                :label="$t('Search for an address')"
                append-icon="mdi-magnify"
                @input="onSearch"
                @focus="handleSearchFieldFocus(true)"
                @blur="handleSearchFieldFocus(false)"
                @click:clear.prevent="clearSearchResults"
                clearable
                hide-details
              />
              <v-list class="autocomplete-results" :class="{active: showAddressesDropdown}">
                <template v-if="searchResults.length">
                  <v-list-item v-for="(result, index) in searchResults" :key="index" @click="selectResult(result)">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ result.display_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <dropdown-search-result-loader v-if="searchField.loading" />
                      <v-list-item-title v-else>
                        {{ emptyResultMessage }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <vue-phone-number-input
                :default-country-code="formData.countryCode"
                :label="$t('Phone number *')"
                required
                autocomplete="on"
                v-model.trim="formData.phone_number"
                @update="phoneNumberValidate"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch :label="$t('Enable address')" color="blue" v-model="address.enabled" style="margin: 0"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn dark depressed class="primary mr-2" @click.native="closeAddressDialog">{{ $t('Cancel') }}</v-btn>
          <v-btn dark depressed class="primary" @click.native="addAddress">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import helperMixin from "@/mixins/helperMixin";
  import DropdownSearchResultLoader from "@/components/DropdownSearchResultLoader.vue";
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
  import { Icon } from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png')
  })

  export default {
    name: 'AddressEditModal',
    components: {
      DropdownSearchResultLoader,
      LMap,
      LTileLayer,
      LMarker
    },
    mixins: [helperMixin],
    props: {
      isShow: {
        type: Boolean,
        required: true
      },
      existingAddress: {
        type: Object,
        required: false
      }
    },
    data: () => ({
      timeout: null,
      mapTimeout: null,
      searchField: {
        value: '',
        loading: true,
        hasFocus: false,
        focusTimeOut: null
      },
      searchResults: [],
      mapCenter: [49.555681281714364, 25.592393875122074],
      markerPosition: [49.555681281714364, 25.592393875122074],
      tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      formData: {
        countryCode: 'UA',
        phone_number: '',
        formattedNumber: '',
        valid: {
          phone_number: false,
        }
      },
      address: {
        enabled: true,
        country: '',
        zip: '',
        city: '',
        state: '',
        street: '',
        longitude: '',
        latitude: '',
        api_details: {}
      },
    }),
    beforeDestroy () {
      if (this.searchField.focusTimeOut) clearTimeout(this.searchField.focusTimeOut);
      if (this.timeout) clearTimeout(this.timeout);
      if (this.mapTimeout) clearTimeout(this.mapTimeout);
    },
    computed: {
      showAddressesDropdown () {
        return this.searchField.value && this.searchField.value.length >= 3 && this.searchField.hasFocus;
      },
      emptyResultMessage () {
        return this.regex.fullAddressStr.test(this.searchField.value) ? this.$t('Not results found') : this.$t('Enter full address')
      }
    },
    methods: {
      closeAddressDialog () {
        this.$emit('hideDialog', {status: false, mode: 'close'}, null)
      },
      handleSearchFieldFocus (state) {
        this.searchField.focusTimeOut = setTimeout(() => {
          this.searchField.hasFocus = state;
        }, 200);
      },
      toggleMarker (event) {
        clearTimeout(this.timeout);
        this.markerPosition = event.latlng;
        this.timeout = setTimeout(() => {
          fetch(`${this.radUrl}/address/suggestion?address=${event.latlng.lat}, ${event.latlng.lng}`)
            .then(response => response.json())
            .then(data => {
              data = data.length ? data[0] : null;
              if (!data) {
                return;
              }
              const resultAddress = data.address;
              this.address = {
                ...this.address,
                api_details: JSON.stringify(data),
                longitude: data.lon,
                latitude: data.lat,
                country: resultAddress.country || '-',
                zip: resultAddress.postcode || '10000',
                city: resultAddress.city || resultAddress.village || resultAddress.town || '-',
                state: resultAddress.suburb || resultAddress.state || '-',
                street: resultAddress.road || '-'
              };
              this.searchField.value = data.display_name;
            })
            .catch(error => {
              console.error('Error fetching data from Photon API: ', error);
            })
        }, 500)
      },
      addAddress () {
        if (!this.searchField.value) {
          return this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Need to select address'),
            action1: { label: this.$t('OK') }
          })
        }
        if (!this.formData.phone_number) {
          return this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Phone number is required field!'),
            action1: { label: this.$t('OK') }
          })
        }
        if (!this.formData.valid.phone_number) {
          return this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Phone number is not valid!'),
            action1: { label: this.$t('OK') }
          })
        }
        const sentData = {
          ...this.address,
          phone_number: this.formData.formattedNumber
        }
        this.$emit('hideDialog', {status: true, mode: this.existingAddress ? 'update' : 'add'}, sentData)
        return this.closeAddressDialog()
      },
      onSearch () {
        this.searchField.loading = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.requestSuggestion()
        }, 500)
      },
      clearSearchResults () {
        this.searchField.value = '';
        this.searchField.loading = true;
        this.timeout = setTimeout(() => {
          this.searchResults = []
        }, 500)
      },
      async requestSuggestion () {
        if ((this.searchField.value && this.searchField.value.length < 3) || !this.searchField.value) {
          this.searchResults = []
          this.searchField.loading = true
          return
        }
        await fetch(`${this.radUrl}/address/suggestion?address=${this.searchField.value}`)
          .then(response => response.json())
          .then(data => {
            this.searchResults = data
          })
          .catch(error => {
            console.error('Error fetching data from Photon API: ', error)
            this.searchResults = []
          })
          .finally(() => {
            this.searchField.loading = false
          })
      },
      selectResult (result) {
        const resultAddress = result.address
        this.address = {
          ...this.address,
          api_details: JSON.stringify(result),
          longitude: result.lon,
          latitude: result.lat,
          country: resultAddress.country_code || '-',
          zip: resultAddress.postcode || '10000',
          city: resultAddress.city || resultAddress.village || resultAddress.town || '-',
          state: resultAddress.suburb || resultAddress.state || '-',
          street: resultAddress.road || '-'
        }
        if (resultAddress.country_code) {
          this.formData.countryCode = resultAddress.country_code.toUpperCase()
        }
        this.mapCenter = [result.lat, result.lon]
        this.markerPosition = [result.lat, result.lon]
        this.searchField.value = result.display_name
        this.timeout = setTimeout(() => {
          this.searchField.hasFocus = false;
        }, 500)
      },
      phoneNumberValidate (event) {
        this.formData.valid.phone_number = event.isValid
        this.formData.formattedNumber = event.formattedNumber
      }
    },
    watch: {
      isShow () {
        this.mapTimeout = setTimeout(() => {
          this.$refs.partnerAddressMap.mapObject.invalidateSize();
        }, 100);
        if (!this.isShow) {
          this.searchField.value = ''
          this.searchResults = []
          this.mapCenter = [49.555681281714364, 25.592393875122074]
          this.markerPosition = [49.555681281714364, 25.592393875122074]
          this.address = {
            enabled: true,
            country: '',
            zip: '',
            city: '',
            state: '',
            street: '',
            longitude: '',
            latitude: '',
            api_details: {}
          }
          this.formData.countryCode = 'UA'
          this.formData.phone_number = ''
          this.formData.formattedNumber = ''
          this.formData.valid.phone_number = false
          return
        }
        if (this.existingAddress) {
          this.searchField.value = JSON.parse(this.existingAddress.api_details).display_name
          this.address = this.existingAddress
          this.mapCenter = [parseFloat(this.existingAddress.latitude), parseFloat(this.existingAddress.longitude)]
          this.markerPosition = [parseFloat(this.existingAddress.latitude), parseFloat(this.existingAddress.longitude)]
          if (this.existingAddress.phone_number) {
            this.formData.countryCode = this.existingAddress.country.toUpperCase()
            this.formData.phone_number = this.existingAddress.phone_number
            this.formData.formattedNumber = this.existingAddress.phone_number
            this.formData.valid.phone_number = true
            return
          }
        }
        this.formData.countryCode = 'UA'
        this.formData.phone_number = ''
        this.formData.formattedNumber = ''
        this.formData.valid.phone_number = false
      },
      searchField () {
        if (!this.searchField.value) {
          this.searchResults = []
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .autocomplete-results {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    width: calc(100% - 48px);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10%);
    transition: all 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  .addresses-popup__title {
    color: white;
  }
  .addresses-popup__content_search-field {
    padding: 0;
    margin: 0;
  }
</style>

<style lang="scss">
  .addresses-popup {
    overflow-y: visible;
  }
  .v-dialog > .v-card > .v-card__text.addresses-popup__content {
    padding: 20px 24px;
  }
</style>
