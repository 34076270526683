<template>
  <tr class="table-line" @click="onOpenEditAddressModal">
    <td v-for="(value, key, index) in item" :key="index" :style="{display: key === 'id' ? 'none' : 'table-cell'}">
      <span v-if="key === 'enabled'">
        <v-switch
          color="green"
          v-model="item[key]"
          @click.stop
          @change="(event) => toggleAddressEnabled(event, item.id)"
          hide-details
          class="switcher"
        />
      </span>
      <template v-else>
        {{value}}
      </template>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";

  export default {
    name: 'AddressInformationTableLine',
    props: {
      item: {
        type: Object,
        required: true,
        default: null,
      }
    },
    computed: {
      ...mapGetters({
        partnerAddressesList: 'partner/address/getPartnerAddressesList'
      })
    },
    methods: {
      async toggleAddressEnabled (addressEnabledStatus, addressId) {
        const address = {
          ...this.partnerAddressesList.find(address => address.id === addressId),
          enabled: addressEnabledStatus
        }
        this.$bus.$emit('update-address-data', address)
      },
      onOpenEditAddressModal () {
        this.$bus.$emit('open-edit-address-modal', this.item.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-line {
    height: 45px;
    cursor: pointer;
    td {
      padding: 0px 16px;
    }
    &:hover {
      background: #eeeeee;
    }
  }
  .switcher {
    margin: 0;
    padding: 0;
  }
</style>
