<template>
  <section>
    <!-- Special Schedule -->
    <v-row class="m-0">
      <v-col cols="12" class="px-0_5">
        <v-card>
          <v-card-title>
            {{ $t('Schedule Special') }}
          </v-card-title>
          <v-card-text>
            <schedule-special :partner-type="partnerType"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ScheduleSpecial from '@/components/GeneralInformation/ScheduleInformation/ScheduleSpecial'

export default {
  name: 'ScheduleSpecialInformation',
  components: {
    ScheduleSpecial
  },
  data: () => ({
    schedule: {},
    partnerType: 'food',
    menuFrom: false,
    menuTo: false
  }),
  mounted () {
    this.$bus.$on('schedule-special-changed', this.setSpecial)
    this.schedule = this.getScheduleInformation
  },
  beforeDestroy () {
    this.$bus.$off('schedule-special-changed', this.setSpecial)
  },
  methods: {
    setSpecial (specialSchedule) {
      this.schedule.special = specialSchedule
    },
    saveData () {
      this.$bus.$emit('partner-schedule-special-section-change', this.schedule)
    }
  },
  computed: {
    ...mapGetters({
      getScheduleInformation: 'user/getUserScheduleInformation',
    }),
  },
  watch: {
    schedule: {
      deep: true,
      handler: function () {
        this.saveData()
      }
    }
  }
}
</script>
