<template>
  <v-container class="general-information__page-content">
    <v-row  >
      <v-col cols="12"><h1 class="font-weight-medium heading text-h4">{{ $t('General Information') }}</h1></v-col>
    </v-row>
    <v-row class="general-information__data-form">
      <v-col cols="12" class="general-information__data-form_content">
        <v-tabs class="general-information__tabs" active-class="general-information__tab--active" v-model="activeTab" slider-color="primary">
          <v-tab>{{ $t('General') }}</v-tab>
          <v-tab-item>
            <general />
          </v-tab-item>

          <v-tab>{{ $t('Delivery Information') }}</v-tab>
          <v-tab-item>
            <keep-alive>
              <component v-bind:is="DeliveryInformation"/>
            </keep-alive>
          </v-tab-item>

          <v-tab>{{ $t('Delivery Schedule') }}</v-tab>
          <v-tab-item>
            <keep-alive>
              <component v-bind:is="ScheduleRegularInformation"/>
            </keep-alive>
          </v-tab-item>

          <v-tab>{{ $t('Self-Pickup Schedule') }}</v-tab>
          <v-tab-item>
            <keep-alive>
              <component v-bind:is="ScheduleSelfPickupInformation"/>
            </keep-alive>
          </v-tab-item>

          <v-tab>{{ $t('Special Days Schedule') }}</v-tab>
          <v-tab-item>
            <keep-alive>
              <component v-bind:is="ScheduleSpecialInformation"/>
            </keep-alive>
          </v-tab-item>

          <v-tab>{{ $t('Address information') }}</v-tab>
          <v-tab-item>
            <keep-alive>
              <component v-bind:is="AddressInformation"/>
            </keep-alive>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider class="mb-4"/>
    <v-row>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn dark depressed class="primary" @click.native="saveAction">{{ $t('Save') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import {
    General,
    DeliveryInformation,
    ScheduleRegularInformation,
    ScheduleSelfPickupInformation,
    ScheduleSpecialInformation,
    AddressInformation
  } from '@/components/GeneralInformation'

  export default {
    components: {
      General
    },
    data: () => ({
      activeTab: 0,
    }),
    async beforeMount () {
      await this.loadUserGeneralInformation({token: this.token});
    if (!this.partnerAddressesList.length) {
      await this.loadPartnerAddressesList({token: this.token, per_page: 100, page: 1})
    }
  },
  mounted () {
    this.$bus.$on('partner-general-section-change', this.generalSectionChangeAfter)
    this.$bus.$on('partner-content-change', this.contentSectionChangeAfter)
    this.$bus.$on('partner-delivery-section-change', this.deliverySectionChangeAfter)
    this.$bus.$on('partner-schedule-regular-section-change', this.scheduleRegularSectionChangeAfter)
    this.$bus.$on('partner-schedule-pickup-section-change', this.scheduleSelfPickupSectionChangeAfter)
    this.$bus.$on('partner-schedule-special-section-change', this.scheduleSpecialSectionChangeAfter)
  },
  beforeDestroy () {
    this.$bus.$off('partner-general-section-change', this.generalSectionChangeAfter)
    this.$bus.$off('partner-content-change', this.contentSectionChangeAfter)
    this.$bus.$off('partner-delivery-section-change', this.deliverySectionChangeAfter)
    this.$bus.$off('partner-schedule-regular-section-change', this.scheduleRegularSectionChangeAfter)
    this.$bus.$off('partner-schedule-pickup-section-change', this.scheduleSelfPickupSectionChangeAfter)
    this.$bus.$off('partner-schedule-special-section-change', this.scheduleSpecialSectionChangeAfter)
  },
  methods: {
    ...mapActions({
      loadUserGeneralInformation: 'user/loadUserGeneralInformation',
      loadPartnerAddressesList: 'partner/address/loadPartnerAddressesList',
      saveGeneralSection: 'user/saveGeneralSection',
      saveContentSection: 'user/saveContentSection',
      saveDeliverySection: 'user/saveDeliverySection',
      saveScheduleRegularSection: 'user/saveScheduleRegularSection',
      saveScheduleSelfPickupSection: 'user/saveScheduleSelfPickupSection',
      saveScheduleSpecialSection: 'user/saveScheduleSpecialSection',
      updatePartner: 'user/updatePartner',
    }),
    generalSectionChangeAfter (sectionData) {
      this.saveGeneralSection(sectionData)
    },
    contentSectionChangeAfter (sectionData) {
      this.saveContentSection(sectionData)
    },
    deliverySectionChangeAfter (sectionData) {
      this.saveDeliverySection(sectionData)
    },
    scheduleRegularSectionChangeAfter (sectionData) {
      this.saveScheduleRegularSection(sectionData)
    },
    scheduleSelfPickupSectionChangeAfter (sectionData) {
      this.saveScheduleSelfPickupSection(sectionData)
    },
    scheduleSpecialSectionChangeAfter (sectionData) {
      this.saveScheduleSpecialSection(sectionData)
    },
    async updatePartnerValidation () {
      let response = {
        status: 200,
        data: {
          message: 'Partner validation successfully',
          section: 0,
          errorFields: []
        }
      }

      if (!this.partnerAddressesList.length && this.getGeneralInformation.general.enabled) {
        response = {
          status: 400,
          data: {
            message: this.$t('You cannot enable partner without address'),
            section: 6
          }
        }
      }
      if (!this.getGeneralInformation.general.logo_path.trim()) {
        response = {
          status: 400,
          data: {
            message: this.$t('You cannot save a partner without a logo!'),
            section: 0,
            errorFields: []
          }
        }
      }
      if (!this.getGeneralInformation.general.name.trim()) {
        response = {
          status: 400,
          data: {
            message: this.$t('You cannot save a partner without a name: this field is required!'),
            section: 0,
            errorFields: [
              ...response.data.errorFields,
              'name'
            ]
          }
        }
      }
      if (!this.getGeneralInformation.delivery.delivery_available && !this.getGeneralInformation.delivery.pickup_available) {
        response = {
          status: 400,
          data: {
            message: this.$t('You cannot disable all delivery methods in the Service type section!'),
            section: 1,
            errorFields: []
          }
        }
      }
      if (this.getGeneralInformation.delivery.delivery_available && !this.getGeneralInformation.schedule.regular.find(a => a['hours'].length)) {
        response = {
          status: 400,
          data: {
            message: this.$t('Please add Regular Schedule or switch off Delivery available!'),
            section: 2,
            errorFields: []
          }
        }
      }
      if (this.getGeneralInformation.delivery.pickup_available && !this.getGeneralInformation.schedule.pickup.find(a => a['hours'].length)) {
        response = {
          status: 400,
          data: {
            message: this.$t('Please add Pickup Schedule or switch off Pickup available!'),
            section: 3,
            errorFields: []
          }
        }
      }
      this.$bus.$emit('partner-general-section-update-error-fields', response.data.errorFields);
      return response;
    },
    async saveAction () {
      await this.updatePartnerValidation().then(response => {
        if (response.status === 200) {
          this.updatePartner({token: this.token, payload: this.getGeneralInformation})
            .then(response => {
              if (response.status === 'success') {
                this.$bus.$emit('partner-delivery-information-section-update-asap-delivery', response.result.delivery.asap_available);
                this.$store.dispatch('notification/spawnNotification', {
                  type: response.status,
                  message: this.$t('General information updated successfully!'),
                  action1: {label: this.$t('OK')}
                })
              } else {
                this.$store.dispatch('notification/spawnNotification', {
                  type: 'error',
                  message: (response.message) ? response.message : this.$t('Something went wrong when updating General Information!'),
                  action1: {label: this.$t('OK')}
                })
              }
            })
            .catch(error => {
              console.error('Something went wrong when updating General Information. Error: ', error)
            })
          return
        }
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: response.data.message,
          action1: { label: this.$t('OK') }
        });
        this.activeTab = response.data.section;
      })
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      getGeneralInformation: 'user/getUserGeneralInformation',
      partnerAddressesList: 'partner/address/getPartnerAddressesList'
    }),
    DeliveryInformation() {
      return DeliveryInformation
    },
    ScheduleRegularInformation() {
      return ScheduleRegularInformation
    },
    ScheduleSelfPickupInformation() {
      return ScheduleSelfPickupInformation
    },
    ScheduleSpecialInformation() {
      return ScheduleSpecialInformation
    },
    AddressInformation() {
      return AddressInformation
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../css/variables/colors';

  .general-information__page-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 64px);
    overflow: hidden;
    max-width: unset;
  }
  .row.general-information__data-form {
    flex: 1 1 auto;
    max-height: 100%;
    overflow: hidden;
    margin-top: 0;
  }
  .general-information__data-form_content {
    max-height: 100%;
    overflow-y: hidden;
  }
  .general-information__tabs {
    max-height: 100%;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .general-information__tabs .general-information__tab {
    &--active {
      color: map-get($colors, primary);
    }
  }
</style>

<style lang="scss">
  @import '../css/variables/colors';

  .general-information__page-content > .row {
    flex: initial;
  }

  .general-information__tabs {
    & .v-slide-group {
      &__prev, &__next {
        min-width: 24px;
        flex: 0 1 24px;
      }

      &__prev {
        margin-right: 12px;
      }

      &__next {
        margin-left: 12px;
      }
    }
  }

  .general-information__tabs .v-slide-group__wrapper {
    border-bottom: 1px solid map-get($colors, gainsboro);
    min-height: 48px;
  }
  .general-information__tabs > .v-tabs-bar > .v-slide-group__prev--disabled,
  .general-information__tabs > .v-tabs-bar > .v-slide-group__next--disabled{
    min-width: auto;
    flex: none;
    margin: 0;
  }
  .general-information__tabs > .v-tabs-items {
    overflow: auto;
    scrollbar-width: thin;
    height: 100%;
  }
  .general-information__tabs > .v-tabs-items > .v-window__container > .v-window-item {
    height: 100%;
  }
</style>
