<template>
  <section class="general-information__root">
    <v-row class="m-0">
      <v-col cols="12" class="px-0_5">
        <v-card>
          <v-card-title>
            {{ $t('General') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8" class="general-information__form-content">
                <v-text-field
                  name="name"
                  v-model="partner.name"
                  :label="$t('Name')"
                  hide-details
                  disabled
                  :error="errorFields.includes('name')"
                />
                <v-text-field
                  name="about"
                  v-model="partner.about"
                  :label="$t('Partner description')"
                  hide-details
                />
                <v-text-field
                  name="minimal_order_amount"
                  v-model="partner.minimal_order_amount"
                  :label="$t('Minimal order amount')"
                  hide-details
                />
                <!-- Service type -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="auto">
                        <v-switch
                          name="active"
                          v-model="comingSoon"
                          ref="active"
                          :label="$t('Coming soon')"
                          inset flat hide-details
                          class="data-switcher"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- Content information -->
                <v-row class="mt-0">
                  <v-col cols="12">
                    <v-row class="align-center">
                      <v-col cols="12">
                        <v-switch
                          name="cutlery_availability"
                          v-model="content.cutlery_availability"
                          ref="cutlery_availability"
                          :label="$t('Сutlery available')"
                          inset flat hide-details
                          class="data-switcher mt-0"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="content.selected_tags"
                          :label="$t('Select Tags')"
                          :items="content.all_tags"
                          multiple
                          chips
                          item-text="title"
                          item-value="id"
                          hide-details
                          class="data-text-field data-select"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="general-information__logo-container">
                <v-avatar
                  :tile="false"
                  :size="200"
                  @click="$refs.logoInput.click()"
                  class="partner-logo-wrap"
                >
                  <img
                    :src="`${logoImage}`"
                    alt="Partner Logo"
                    class="partner-logo"
                  />
                  <div class="primary partner-logo-upload-label" :class="{'hidden': !!partner.logo_path}">
                    {{ $t('Click here to choose logo') }}
                  </div>
                  <input
                    hidden
                    type="file"
                    accept='.png,.jpg,.jpeg,.svg,.webp'
                    @change="changeLogo"
                    name="logo_input"
                    ref="logoInput"
                  />
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'General',
  data: () => ({
    partner: {
      name: '',
      about: '',
      logo_path: '',
      active: '',
      minimal_order_amount: ''
    },
    content: {
      banner: '',
      cutlery_availability: 0,
      all_tags: [],
      selected_tags: []
    },
    errorFields: []
  }),
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      generalInformation: 'user/getUserGeneralDataInformation',
      getContentInformation: 'user/getContentInformation'
    }),
    comingSoon: {
      get () {
        return !this.partner.active
      },
      set (newValue) {
        this.partner.active = !newValue
      }
    },
    logoImage () {
      return this.partner.logo_path ? process.env.VUE_APP_BASE_IMAGE_URL + this.partner.logo_path : 'https://www.korzyna.com/assets/placeholder.svg'
    }
  },
  methods: {
    ...mapActions({
      uploadImage: 'user/uploadImage'
    }),
    loadData () {
      this.partner = this.generalInformation
      this.content = this.getContentInformation
    },
    saveData () {
      this.$bus.$emit('partner-general-section-change', this.partner)
    },
    saveContentData () {
      this.$bus.$emit('partner-content-change', this.content)
    },
    updateErrorFields (fields) {
      this.errorFields = fields
    },
    changeLogo (event) {
      this.partner.logo_file = event.target.files[0]
      this.uploadImage({ token: this.token, image: this.partner.logo_file, type: 'logo_file' })
        .then(response => {
          if (response.status == 'error') {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: (response.message) ? response.message : this.$t('Something went wrong when updating logo image!'),
              action1: {label: this.$t('OK')}
            })
          } else {
            this.partner.logo_path = response.path
          }
          this.partner.logo_file = null
        })
        .catch(err => {
          console.error('Something went wrong: ' + err)
        })
    },
  },
  mounted () {
    this.loadData();

    this.$bus.$on('partner-general-section-update-error-fields', this.updateErrorFields);
  },
  beforeDestroy () {
    this.$bus.$off('partner-general-section-update-error-fields', this.updateErrorFields);
  },
  watch: {
    partner: {
      deep: true,
      handler: function () {
        this.saveData()
      }
    },
    content: {
      deep: true,
      handler: function () {
        this.saveContentData()
      }
    },
    generalInformation () {
      if (JSON.stringify(this.partner) !== JSON.stringify(this.generalInformation)) this.loadData();
    }
  }
}
</script>

<style scoped lang="scss">
  .general-information__root {
    height: 100%;
  }
  .general-information__logo-container {
    display: flex;
    justify-content: center;
  }
  .general-information__form-content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .partner-logo-wrap {
    cursor: pointer;
  }
  .partner-logo {
    border-radius: unset;
    height: unset;
    max-width: 200px;
  }
  .partner-logo-upload-label {
    position: absolute;
    color: white;
    padding: 7px;
    border-radius: 15px;
  }
  .hidden {
    visibility: hidden;
  }
  .partner-logo-wrap:hover .partner-logo-upload-label {
    visibility: visible;
  }
</style>
