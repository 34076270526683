<template>
  <section>
    <v-row class="m-0">
      <v-col cols="12" class="px-0_5">
        <v-card>
          <v-card-title>
            {{ $t('Work schedule') }}
          </v-card-title>
          <v-card-text>
            <!-- Work Schedule -->
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="delivery.working_schedule"
                  class="elevation-1 address-information__table"
                  hide-default-footer
                  hide-default-header
                >
                  <template v-slot:body="{ items }">
                      <tr class="option-line" v-for="(item) in items" :key="item.day" >
                        <td class="left">
                          <v-switch
                            color="green"
                            v-model="item.enable"
                            :label=$t(item.name)
                            hide-details
                            class="switcher"
                          />
                        </td>
                        <td class="center">
                          <v-select class="time-select"
                                    v-model="item.from"
                                    :items="time_ranges"
                                    item-text="code"
                                    item-value="code"
                                    data-vv-validate-on="change|blur"
                                    :disabled="!item.enable"
                                    hide-details
                          ></v-select>
                        </td>
                        <td class="center">
                          <v-select class="time-select"
                                    v-model="item.to"
                                    :items="time_ranges"
                                    item-text="code"
                                    item-value="code"
                                    data-vv-validate-on="change|blur"
                                    :disabled="!item.enable"
                                    hide-details
                          ></v-select>
                        </td>
                      </tr>
                    </template>
                </v-data-table>
              </v-col>
            </v-row>
            <!-- Service type -->
            <v-row>
              <v-col cols="12">
                <h4>{{ $t('Service type') }}</h4>
                <v-row>
                  <v-col cols="auto">
                    <v-switch
                      name="delivery_available"
                      v-model="delivery.available_options.delivery_available"
                      ref="delivery_available"
                      :label="$t('Delivery available')"
                      inset flat hide-details
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      name="pickup_available"
                      v-model="delivery.available_options.pickup_available"
                      ref="pickup_available"
                      :label="$t('Pickup is available')"
                      inset flat hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Delivery options -->
            <v-row>
              <v-col cols="12">
                <h4>{{ $t('Delivery options') }}</h4>
                <v-row>
                  <v-col cols="3">
                    <v-switch
                      name="asap_available"
                      v-model="delivery.available_options.asap_available"
                      ref="asap_available"
                      :label="$t('ASAP')"
                      :disabled = "!isDeliveryEnabled"
                      inset flat hide-details
                      class="data-switcher mt-0"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";
import * as config from "@/config/default.json";

export default {
  name: 'DeliveryInformation',
  data: () => ({
    time_range_from_default: '09:00',
    time_range_to_default: '23:00',
    time_range_step: 15,
    time_ranges: [],
    delivery: {
      available_options: {
        delivery_available: 0,
        pickup_available: 0,
        asap_available: 0,
      },
      working_schedule: []
    },
    headers: [
      { title: 'Id',align: 'start',key: 'id'},
      { title: 'Day',align: 'start',key: 'name'},
      { title: 'From', align: 'start', key: 'from' },
      { title: 'To', align: 'start', key: 'to' }
    ],
    defaultWorkingHours: []
  }),
  mounted () {
    this.$bus.$on('partner-delivery-information-section-update-asap-delivery', this.updateAsapAvailable)
    this.time_range_from_default = (config.partner.working_schedule.time_range_from) ? config.partner.working_schedule.time_range_from : this.time_range_from_default
    this.time_range_to_default = (config.partner.working_schedule.time_range_to) ? config.partner.working_schedule.time_range_to : this.time_range_to_default
    this.time_range_step = (config.partner.working_schedule.time_range_step) ? config.partner.working_schedule.time_range_step : this.time_range_step
    this.delivery.available_options.delivery_available = this.getDeliveryInformation.delivery_available
    this.delivery.available_options.pickup_available = this.getDeliveryInformation.pickup_available
    this.delivery.available_options.asap_available = this.getDeliveryInformation.asap_available
    this.delivery.working_schedule = this.getDeliveryInformation.working_schedule
    if (!this.delivery.working_schedule || this.delivery.working_schedule.length == 0) {
      let time_range_from = this.getDeliveryInformation.time_from ? this.getDeliveryInformation.time_from.substring(0, 5) : this.time_range_from_default
      let time_range_to = this.getDeliveryInformation.time_to ? this.getDeliveryInformation.time_to.substring(0, 5) : this.time_range_to_default
      this.defaultWorkingHours = [
        {day: 'sunday', name: 'Sunday', from: time_range_from, to: time_range_to, enable: true},
        {day: 'monday', name: 'Monday',  from: time_range_from, to: time_range_to, enable: true},
        {day: 'tuesday', name: 'Tuesday', from: time_range_from, to: time_range_to, enable: true},
        {day: 'wednesday', name: 'Wednesday', from: time_range_from, to: time_range_to, enable: true},
        {day: 'thursday', name: 'Thursday', from: time_range_from, to: time_range_to, enable: true},
        {day: 'friday', name: 'Friday', from: time_range_from, to: time_range_to, enable: true},
        {day: 'saturday', name: 'Saturday', from: time_range_from, to: time_range_to, enable: true}
      ]
      this.delivery.working_schedule = this.defaultWorkingHours
    }
    this.createWorkingTimeRanges(this.time_range_from_default, this.time_range_to_default, this.time_range_step)
  },
  beforeDestroy () {
    this.$bus.$off('partner-delivery-information-section-update-asap-delivery', this.updateAsapAvailable)
  },
  methods: {
    updateAsapAvailable(asap) {
      this.delivery.available_options.asap_available = asap
    },
    createWorkingTimeRanges(from, to, step) {
      let start = this.getDateFromHours(from);
      let finish = this.getDateFromHours(to);
      for (let i = start.getTime(); i < finish.getTime(); i += step * 60000) {
        this.time_ranges.push({
          code: moment(new Date(i)).format('HH:mm')
        })
      }
      this.time_ranges.push({
        code: to
      })
    },
    getDateFromHours(time) {
      time = time.split(':');
      let now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time);
    },
    saveData () {
      this.$bus.$emit('partner-delivery-section-change', this.delivery)
    }
  },
  computed: {
    ...mapGetters({
      getDeliveryInformation: 'user/getUserDeliveryInformation'
    }),
    isDeliveryEnabled () {
      return this.delivery.available_options.delivery_available
    }
  },
  watch: {
    delivery: {
      deep: true,
      handler: function () {
        this.saveData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  // working schedule table
  .option-line {
    td {
      padding: 0px 16px;
      border-bottom: 1px solid #bebebe;
      .switcher {
        margin: 0;
        padding: 0;
      }

      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-right: 24px;
      }
    }
  }

  // services
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }
  .no-padding {
    padding: 0 0
  }
  .no-marging {
    marging: 0 0;
  }
</style>
<style lang="scss">
  .time-select {
    margin: 6px 0;
    padding: 0;
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
</style>
