<template>
  <v-row>
    <!-- Time From -->
    <v-col cols="4">
      <v-menu
        offset-y
        ref="menu_from"
        v-model="menuFrom"
        :close-on-content-click="false"
        :return-value.sync="timeFrom"
        :nudge-top="24"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            readonly
            slot="activator"
            v-model="timeFrom"
            :label="$t('From', { symbol: ' *' })"
            v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushWorkHoursFrom"
            hide-details
          />
        </template>
        <v-time-picker v-if="menuFrom" full-width v-model="timeFrom">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuFrom = false">{{ $t('Cancel') }}</v-btn>
          <v-btn text color="primary" @click="saveTimeFrom">{{ $t('OK') }}</v-btn>
        </v-time-picker>
      </v-menu>
    </v-col>
    <!-- Time To -->
    <v-col cols="4">
      <v-menu
        offset-y
        ref="menu_to"
        v-model="menuTo"
        :close-on-content-click="false"
        :return-value.sync="timeTo"
        :nudge-top="24"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            readonly
            slot="activator"
            v-model="timeTo"
            :label="$t('To', { symbol: ' *' })"
            v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushWorkHoursTo"
            hide-details
          />
        </template>
        <v-time-picker v-if="menuTo" full-width v-model="timeTo">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuTo = false">{{ $t('Cancel') }}</v-btn>
          <v-btn text color="primary" @click="saveTimeTo">{{ $t('OK') }}</v-btn>
        </v-time-picker>
      </v-menu>
    </v-col>
    <v-spacer/>
    <v-col cols="auto">
      <v-btn v-if="mode === 'add'" class="primary mr-2 my-1 white--text save-window" depressed dark @click="add">
        {{ $t('Save') }}
      </v-btn>
      <v-btn v-if="mode === 'edit'" class="mr-2 my-1 white--text save-window" color="red" depressed dark @click="remove">
        {{ $t('Delete') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WindowForm',
  props: {
    day: {
      type: String,
      required: true
    },
    index: {
      type: Number
    },
    type: {
      type: String,
      default: 'regular'
    },
    mode: {
      type: String,
      default: 'add'
    },
    editTimeFrom: {
      type: String,
      required: false
    },
    editTimeTo: {
      type: String,
      required: false
    }
  },
  mounted () {
    if (this.mode === 'edit') {
      this.timeFrom = this.editTimeFrom
      this.timeTo = this.editTimeTo
    }
  },
  data () {
    return {
      timeFrom: null,
      menuFrom: false,
      timeTo: null,
      menuTo: false,
      tab: 0
    }
  },
  methods: {
    onTab(index) {
      this.tab = index
    },

    saveTimeFrom () {
      this.$refs.menu_from.save(this.timeFrom)
      if (this.mode === 'edit') {
        this.edit()
      }
    },

    saveTimeTo () {
      this.$refs.menu_to.save(this.timeTo)
      if (this.mode === 'edit') {
        this.edit()
      }
    },

    add () {
      if (this.timeFrom && this.timeTo && this.timeFrom < this.timeTo) {
        this.$bus.$emit(`schedule-${this.type}-window-add`, { from: this.timeFrom, to: this.timeTo, day: this.day })
      }
      this.timeFrom = null
      this.timeTo = null
    },

    edit () {
      this.$bus.$emit(`schedule-${this.type}-window-edit`, { from: this.timeFrom, to: this.timeTo, day: this.day }, this.index)
    },

    remove () {
      this.$bus.$emit(`schedule-${this.type}-window-remove`, { from: this.timeFrom, to: this.timeTo, day: this.day })
    },

    flushWorkHoursFrom() {
      this.timeFrom = ''
    },
    flushWorkHoursTo() {
      this.timeTo = ''
    },
  }
}
</script>

<style scoped>
  .save-window {
    top: 8px;
  }
</style>
