<template>
  <v-layout>
    <!-- Date From -->
    <v-flex xs11 sm6>
      <v-menu offset-y
              ref="menu_date"
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="specialDate"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            readonly
            class="mx-3 my-4"
            slot="activator"
            v-model="specialDate"
            :label="$t('From date *')"
            v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushWorkDay"
            hide-details
          />
        </template>
        <v-date-picker v-if="menuDate" full-width
                       v-model="specialDate"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuDate = false">{{ $t('Cancel') }}</v-btn>
          <v-btn text color="primary" @click="$refs.menu_date.save(specialDate)">{{ $t('OK') }}</v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>
    <!-- Date To -->
    <v-flex xs11 sm6>
      <v-menu offset-y
              ref="menu_date_to"
              v-model="menuDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="specialDateTo"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            readonly
            class="mx-3 my-4"
            slot="activator"
            v-model="specialDateTo"
            :label="$t('To date *')"
            v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushWorkDayTo"
            hide-details
          />
        </template>
        <v-date-picker v-if="menuDateTo" full-width
                       v-model="specialDateTo"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuDateTo = false">{{ $t('Cancel') }}</v-btn>
          <v-btn text color="primary" @click="$refs.menu_date_to.save(specialDateTo)">{{ $t('OK') }}</v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs1 sm1>
      <v-btn dark depressed class="primary mr-2 my-1 save-date"
             @click="save"
      >
        {{ $t('Add') }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'DateForm',
  data () {
    return {
      menuDate: false,
      menuDateTo: false,
      specialDate: null,
      specialDateTo: null
    }
  },
  methods: {
    onTab(index) {
      this.tab = index
    },
    flushWorkDay() {
      this.specialDate = ''
    },
    flushWorkDayTo() {
      this.specialDateTo = ''
    },
    save () {
      this.$bus.$emit('schedule-special-date-add', { day: this.specialDate, day_to: this.specialDateTo, hours: [] })
      this.specialDate = null
      this.specialDateTo = null
    }
  }
}
</script>
<style>
  .save-date {
    top: 30px;
  }
</style>
