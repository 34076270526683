<template>
  <div class="loading-container">
    <span v-if="text" class="loading-text">{{ text }}</span>
    <template v-if="dots">
      <span
        v-for="index in 3"
        :key="index"
        class="primary dot"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'DropdownSearchResultLoader',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    dots: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.loading-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: 5px;
}

.loading-text {
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: #333;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0;
  animation: fadeInOut 1.25s infinite ease;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
</style>
